import React from "react";
import "./OverlayAnswer.css";

export function OverlayAnswer({ answer, question }) {
  return (
    <li key={question.text}>
      <div className="assessment-question">
        <p className="assessment-label">{question.text}</p>
        <Question question={question} answer={answer} />
      </div>
    </li>
  );
}

function Question({ question, answer }) {
  if (question.slider) {
    return <Slider question={question} answer={answer} />;
  }
  if (question.statement) {
    return <p>-</p>;
  }
  if (question.choices) {
    return <Choices question={question} answer={answer} />;
  }
  if (question.textinput) {
    return <TextInput question={question} answer={answer} />;
  }

  console.log({ question, answer });
  return <p>Undefined question type</p>;
}

function Slider({ question, answer }) {
  const { slider } = question;
  const v = answer.raw;

  const max = slider.max;

  const pct = (v / max) * 100;

  return (
    <div className="question-slider">
      <div className="labels">
        {slider.number_labels.map((n) => (
          <span className="v">{n}</span>
        ))}
      </div>

      <div className="slider">
        <div className="circle" style={{ marginLeft: `${pct}%` }}></div>
      </div>

      <div className="numbers">
        {slider.text_labels.map((n) => (
          <span className="v">{n}</span>
        ))}
      </div>
    </div>
  );
}

function Choices({ question, answer }) {
  const { choices } = question;
  const v = answer.raw;

  return (
    <ul className="choices">
      {choices.map((c) => (
        <li className={v === c.value ? "checked" : ""}>{c.text}</li>
      ))}
    </ul>
  );
}

function TextInput({ question, answer }) {
  return <p className="textinput">{answer.answer}</p>;
}
