import React from "react";
import PropTypes from "prop-types";

import "./Assessment.css";
import Overlay from "./Overlay";

class Assessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOverlay: false,
    };

    this.clear = this.clear.bind(this);
  }

  clear() {
    this.setState({ showOverlay: false });
    document.location.reload(); // not sure why without reload breaks
  }

  renderLine({ label, value, effect }) {
    return (
      <tr key={value} className={`badge-line effect-${effect}`}>
        <td>{label}</td>
        <td>
          <div className="badge-content">{value}</div>
        </td>
      </tr>
    );
  }

  renderLines(lines) {
    return lines.map(this.renderLine);
  }

  renderBadge(badge) {
    return (
      <li
        className="assessment-score"
        onClick={() => this.setState({ showOverlay: true })}
        key={badge.title}
      >
        <h2>{badge.title}</h2>

        {badge.lines && (
          <table className="assessment-table table">
            <tbody>{this.renderLines(badge.lines)}</tbody>
          </table>
        )}

        {badge.line && (
          <div className="badge-single-line">{badge.line.label}</div>
        )}

        {this.renderOverlay()}
      </li>
    );
  }

  render() {
    const { assessment } = this.props;

    if (!assessment.badges) {
      return null;
    }

    return assessment.badges.map((b) => this.renderBadge(b));
  }

  renderOverlay() {
    const { assessment } = this.props;
    const { showOverlay } = this.state;

    if (showOverlay === true) {
      return <Overlay assessment={assessment} onClear={this.clear} />;
    }
    return null;
  }
}

Assessment.propTypes = {
  // Todo: Be more specific
  assessment: PropTypes.object.isRequired,
};

export default Assessment;
