import React from "react";
import PropTypes from "prop-types";

import "./Overlay.css";
import { OverlayAnswer } from "./OverlayAnswer";

class Overlay extends React.Component {
  render() {
    const { assessment, onClear } = this.props;
    const { questions, formattedAnswers, title } = assessment;

    return (
      <div className="assessment-overlay" onClick={() => onClear()}>
        <div className="container" onClick={(e) => e.stopPropagation()}>
          <h1>{title}</h1>

          <ul className="questions">
            {formattedAnswers.map((a, i) => {
              const q = questions[i];
              return <OverlayAnswer answer={a} question={q} />;
            })}
          </ul>
        </div>
      </div>
    );
  }
}

Overlay.propTypes = {
  // Todo: Be more specific
  assessment: PropTypes.object.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default Overlay;
